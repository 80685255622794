import React from "react";
import SEO from "../../components/seo";
import useProfilePic from "../../hooks/useProfilePic";
import ProfilePage from "../../layouts/ProfilePage";

const Hannah = () => {
	const { hannah: pic } = useProfilePic();
	return (
		<>
			<SEO
				title="Our Team of Specialists"
				keywords={[`Gastroenterologist`, `Dr Hannah Hessamodini`]}
				description="About Dr Hannah Hessamodini"
			/>
			<ProfilePage
				title="Dr Hannah Hessamodini - Gastroenterologist"
				name="Dr Hannah Hessamodini"
				img={pic.childImageSharp.fixed}
				quote=""
				specialty="All aspects of general and luminal gastroenterology including inflammatory bowel disease"
				education={[
					"Undergraduate: University of Melbourne",
					"Postgraduate GI Training: Sir Charles Gairdner Hospital, Perth, Western Australia",
					"Postgraduate GI Training: Royal Perth Hospital, Perth, Western Australia",
					"Postgraduate GI Training: Fiona Stanley Hospital, Perth, Western Australia",
				]}
				description={[
					"Dr Hessamodini is a Specialist Gastroenterologist who has a list at both Glengarry & Hollywood Private Hospitals and consults from rooms in Duncraig and Nedlands.",
					"Dr Hessamodini has an interest in the management of functional gut disorders and nutrition, in addition to coeliac disease, iron deficiency, anaemia and bowel cancer screening and genetic GI cancer syndromes. Dr Hessamodini has conjoint recognition for gastroscopy, colonoscopy & capsule endoscopy with additional training in intestinal ultrasound.",
					"She offers a no gap service for endoscopic procedures.",
				]}
				honours={[
					"",
				]}
				background={[
					"Dr Hannah Hessamodini completed medicine at the University of Melbourne in 2010, and returned to Perth in 2011 commencing her physician training at Sir Charles Gairdner Hospital. She subsequently became a member of the Royal Australian College of Physicians in 2018 after completing her specialty training in Gastroenterology and Hepatology.",
					"During her training, she worked with the Western Australian Liver Transplant Service, the Royal Perth & Sir Charles Gairdner gastroenterology services, and as an endoscopic ultrasound Fellow at Fiona Stanley Hospital. Her special interests include the management of functional gut disorders and nutrition, coeliac disease, iron deficiency anaemia and bowel cancer screening.",
				]}
				appointments={[
					"Private appointment - Hollywood Private Hospital, Perth",
					"Private appointment - Glengarry Private Hospital, Perth",
					"Public appointment - Peel Health Campus",
				]}
				memberships={[
					"The Royal Australasian College of Physicians (RACP)",
					"Conjoint Committee for the Recognition of Training in Gastrointestinal Endoscopy",
					"Australian Health Practitioner Regulation Agency (AHPRA)",
				]}
				personal={[
					"",
				]}
				publications={[
					"",
				]}
			/>
		</>
	);
};

export default Hannah;
